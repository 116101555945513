/**
 *
 *
 *
 *
 */

(function (win) {
  const { getConsentsForAllGVs, getIabConsentString, readVendorsConsents } = otto.cmp;

  /*                                        */
  try {
    /*                                 */
    win.exactag = win.exactag || {};
    win.lhotse = win.lhotse || {};
    win.lhotse.exactag = win.lhotse.exactag || {};

    /*        */
    const loc = win.location;
    const consentPartnerMap = {
      16: "xrh", /*                  */
      39: "xad", /*                 */
      40: "xaa", /*                     */
      44: "xta", /*                 */
      70: "xyl", /*                  */
      559: "xod", /*              */
      755: "xgr", /*                */
      759: "xrj", /*                */
    };
    const onetrustConsentPartnerMap = {
      METAR: "xmt", /*           */
      PINRT: "xpn", /*                */
      TKADV: "xtt", /*             */
      SNAPA: "xsc", /*           */
      MSADV: "xms", /*                */
      ADVMK: "xas", /*                          */
      GOANA: "xga", /*                      */
      GOREM: "xgo", /*             */
      CUSDP: "xis", /*                           */
      SPTFY: "xsy", /*                         */
    };

    /*                                       */
    window.o_util.core.extend(win.lhotse.exactag, {
      version: "0.8.7",
      /*     */
      logger: win.o_global?.debug.logger("exactag.central"),
      /*                                                           */
      debug: loc.href.indexOf("#debug") > -1 || window.o_util.cookie.get("debug") === "true",
      /*                                                               */
      benchmark:
        loc.href.indexOf("#benchmark") > -1 ||
        window.o_util.cookie.get("PartnerPixelBenchmark") === "PixelBenchmark=true",
      /*                                      */
      OptOutMode: false,
      /*                                 */
      testMode: false,
      /*                                 */
      QAMode: false,
      /*                                                               */
      campaignId: "ffaacb82c52393300a9d3b3376091c43",
      /*                                                                  */
      baseParams: [
        "campaign",
        "pt",
        "uu",
        "ls",
        "hc",
        "cs",
        "ag",
        "pz",
        "ka",
        "bp",
        "sf",
        "cb",
        "xo",
        ...Object.entries({ ...consentPartnerMap, ...onetrustConsentPartnerMap }).map(([, v]) => v),
      ],
      consentPartnerMap,
      data: {},
      fns: {},
      first: true,

      /*                                                */
      /*  */
      /*                                           */
      /*                                         */
      /*  */
      /*                                        */
      /*                                           */
      /*         */
      /*                     */
      /*   */
      /*  */
      /*  */
      register(triggerID, fn) {
        win.lhotse.exactag.fns[triggerID] = fn;
      },

      /*                                                     */
      addCampaignId() {
        win.lhotse.exactag.data.campaign = win.lhotse.exactag.campaignId;

        return win.lhotse.exactag.data;
      },

      getCookieParameter() {
        return window.lhotse.exactag.hasConsent() ? "1" : "0";
      },

      /*                                                     */
      addGenericData() {
        /*                                                                 */
        win.lhotse.exactag.data.uu = window.o_util.cookie.get("BrowserId") || "";

        /*                                       */
        win.lhotse.exactag.data.sp = loc.pathname.split(/\?|#/)[0].substring(1);

        /*                                                                 */
        win.lhotse.exactag.data.pt = "Default";

        /*                                       */
        if (
          !!window.o_global.device &&
          !!window.o_global.device.breakpoint &&
          typeof window.o_global.device.breakpoint.getCurrentBreakpoint === "function"
        ) {
          win.lhotse.exactag.data.bp = window.o_global.device.breakpoint.getCurrentBreakpoint();
        }

        /*                                                              */
        win.lhotse.exactag.data.sf = window.o_util.cookie.exists("app") ? "app" : "wwwottode";

        return win.lhotse.exactag.data;
      },

      /*                                                         */
      addElchCookieData() {
        let pmInfo;
        let pm;
        const mo = {};
        let pmInfoCookie;
        const log = win.lhotse.exactag.logger.info;
        try {
          pmInfoCookie = window.o_util.cookie.get("pmInfo") || undefined;

          if (pmInfoCookie !== undefined) {
            /*                                                                                 */
            pmInfo = JSON.parse(pmInfoCookie);

            if (pmInfo.pi_send === "true") {
              log("Marketing object has already been sent.");
              return mo;
            }

            for (pm in pmInfo) {
              /*                                   */
              if (pmInfo.hasOwnProperty(pm) && pm.length === 2) {
                mo[pm] = pmInfo[pm];
              }
            }

            pmInfoCookie = pmInfoCookie.replace(/"}/g, '", "pi_send":"true" }');
            window.o_util.cookie.set("pmInfo", pmInfoCookie, {
              path: "/",
              domain: ".otto.de",
            });

            /*                                                         */
            if (!window.o_util.core.isEmptyObject(mo)) {
              log("Adding marketing object.");
              win.lhotse.exactag.data.mo = mo;
              return mo;
            }
          }
        } catch (err) {
          /*                                                                                  */
          window.o_util.cookie.remove("pmInfo", {
            path: "/",
            domain: ".otto.de",
          });
        }
      },

      /*                                                            */
      addVerticalsData() {
        /*                                                  */
        [].forEach.call(document.querySelectorAll(".exactag"), function (element) {
          /*                                    */
          const triggerID = element.getAttribute("data-trigger");
          /*                                  */
          const fn = win.lhotse.exactag.fns[triggerID];
          const log = win.lhotse.exactag.logger.info;
          const { data } = win.lhotse.exactag;
          let result;

          /*                                            */
          /*                                                      */
          /*                           */
          if (triggerID !== undefined && typeof fn === "function") {
            result = fn();
            if (result && typeof result === "object") {
              if (result === data) {
                /*                                                  */
                /*                                                        */
                log(`Unnecessary return in trigger function "${triggerID}"!`);
              } else {
                /*                                                         */
                window.o_util.core.extend(data, result);
              }
            }
          } else {
            log(`Unknown trigger function "${triggerID}"!`);
          }
        });

        return win.lhotse.exactag.data;
      },

      addPartnerConsents: async () => {
        /*                         */
        try {
          window.lhotse.exactag.data.xo = await getIabConsentString();
        } catch (e) {
          /*    */
        }

        /*                */
        try {
          Object.entries(await getConsentsForAllGVs()).forEach(([onetrustId, consent]) => {
            const exactagId = onetrustConsentPartnerMap[onetrustId];

            if (!!onetrustId && !!exactagId) {
              window.lhotse.exactag.data[exactagId] = consent === "1" ? 1 : 0;
            }
          });
        } catch (e) {
          /*    */
        }

        /*              */
        try {
          (await readVendorsConsents()).forEach((consent, tcfVendorId) => {
            const exactagId = consentPartnerMap[tcfVendorId];

            if (!!tcfVendorId && !!exactagId) {
              /*                                  */
              window.lhotse.exactag.data[exactagId] = consent | 0;
            }
          });
        } catch (e) {
          /*    */
        }
      },

      /*                                                                                           */
      /*                                                                                          */
      cacheUserData() {
        const { data } = win.lhotse.exactag;
        const cache = {};
        let i;
        let param;

        for (i = 0; i < win.lhotse.exactag.baseParams.length; i++) {
          param = win.lhotse.exactag.baseParams[i];
          if (param in data) {
            cache[param] = data[param];
          }
        }

        win.lhotse.exactag.data = cache;

        return win.lhotse.exactag.data;
      },

      /*                                   */
      removeEmptyItems(obj) {
        let item;

        /*                                                                */
        if (!obj) {
          obj = win.lhotse.exactag.data;
        }

        for (item in obj) {
          if (obj.hasOwnProperty(item)) {
            if (typeof obj[item] === "object") {
              win.lhotse.exactag.removeEmptyItems(obj[item]);
            } else if (obj[item] === "") {
              delete obj[item];
            }
          }
        }

        win.lhotse.exactag.data = obj;

        return win.lhotse.exactag.data;
      },

      /*                                                                */
      toggleMode() {
        const isTestCookie = window.o_util.cookie.get("PartnerPixelTest");
        const isQACookie = window.o_util.cookie.get("PartnerPixelPreview");
        const optOutCookie = window.o_util.cookie.get("DS");

        /**
 *
 */
        function setTestFlag() {
          win.lhotse.exactag.logger.info('Sending data with "isTest" flag to exactag!');
          win.lhotse.exactag.data.isTest = "true";
        }

        /**
 *
 */
        function setQAFlag() {
          win.lhotse.exactag.logger.info('Sending data with "isQA" flag to exactag!');
          win.lhotse.exactag.data.isQA = "true";
        }

        /**
 *
 */
        function setOptOutFlag() {
          win.lhotse.exactag.logger.info('Sending data with "ro/optOut" flag to exactag!');
          win.lhotse.exactag.data.ro = "1";
        }

        if (win.lhotse.exactag.testMode) {
          setTestFlag();
        } else if (isTestCookie !== undefined) {
          if (isTestCookie === "PixelTest=true") {
            setTestFlag();
          }
        } else {
          delete win.lhotse.exactag.data.isTest;
        }

        if (win.lhotse.exactag.QAMode) {
          setQAFlag();
        } else if (isQACookie !== undefined) {
          if (isQACookie === "PixelPreview=true") {
            setQAFlag();
          }
        } else {
          delete win.lhotse.exactag.data.isQA;
        }

        if (win.lhotse.exactag.OptOutMode) {
          setOptOutFlag();
        } else if (optOutCookie !== undefined) {
          if (optOutCookie === "retOptOut=1") {
            setOptOutFlag();
          }
        } else {
          delete win.lhotse.exactag.data.ro;
        }
      },

      /*                                                                                       */
      track() {
        if (window.lhotse.exactag.finished) {
          win.lhotse.exactag.continueTrack();
        }

        /*                                 */
        window.lhotse.exactag.centralFinished = true;
      },

      /*                                                          */
      continueTrack() {
        let endTime;
        let timeSinceDomeReady;
        let i;
        let varnish;

        win.lhotse.exactag.data.cb = win.lhotse.exactag.getCookieParameter();

        /*                                     */
        /*                              */
        if (win.lhotse.exactag.varnish && win.lhotse.exactag.first) {
          varnish = win.lhotse.exactag.varnish.data;
          /*                                                           */
          for (i = 0; i < varnish.length; i++) {
            window.o_util.core.extend(win.lhotse.exactag.data, varnish[i]);
          }

          win.lhotse.exactag.first = false;
        }

        /*                     */
        win.lhotse.exactag.removeEmptyItems();

        /*                                                   */
        win.lhotse.exactag.toggleMode();

        /*                          */
        /*                                                  */
        if (
          (window.o_util.cookie.exists("seleniumBypassAdvertising") &&
            window.o_util.cookie.get("seleniumBypassAdvertising") !== "true") ||
          (!window.o_util.cookie.exists("seleniumBypassAdvertising") &&
            window.lhotse.exactag.execute)
        ) {
          window.exactag.track(win.lhotse.exactag.data);
        } /**
 *
 */
        /*                                                                                                         */
        if (win.lhotse.exactag.benchmark) {
          endTime = new Date().getTime();
          timeSinceDomeReady = (endTime - win.lhotse.exactag.startTime) / 1000;
          win.lhotse.exactag.logger.info(
            `Sending data ${timeSinceDomeReady} seconds after DOMReady.`,
          );
        }

        /*                                      */
        win.lhotse.exactag.logger.debug(win.lhotse.exactag.data);

        /*                                                                 */
        win.lhotse.exactag.cacheUserData();
      },

      /*                          */
      init: (function () {
        window.o_global.eventLoader.onReady(10, function () {
          /*                                                                                            */
          if (win.lhotse.exactag.benchmark) {
            win.lhotse.exactag.startTime = new Date().getTime();
          }
        });

        window.o_global.eventLoader.onAllPreloadScriptsLoaded(11, function () {
          win.lhotse.exactag.addCampaignId();
          win.lhotse.exactag.addGenericData();
          win.lhotse.exactag.addElchCookieData();
          win.lhotse.exactag.addVerticalsData();
          win.lhotse.exactag.addPartnerConsents().then(() => {
            win.lhotse.exactag.track();
          });
        });
      })(),
    });
  } catch (err) {
    throw err;
    /*    */
  }
})(window);

export {};
